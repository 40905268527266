
import React from "react"

import ProductRequestEditorPage from "../../components/negosyonow/controls/productrequesteditor";

const webappsAPI = require("../../../lib/requestWebapps");

const nnproductaliaspickerfields = [
	{"label":"Product Line", "dbfield": "nnproductalias_name", "type": "text", "filtertype": "textbox"},
	{"label":"Product Name", "dbfield": "nnproductalias_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nnproductalias_image", "type": "image", "filtertype": "image"},
	{"label":"Seller SKU", "dbfield": "nnproductalias_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Last Update", "dbfield": "nnproductalias_lastupdate", "type": "datetime", "filtertype": "datetime"},
];

const nnproductaliaspickerfilter = [
];

const nntagpickerfields = [
	{"label":"Name", "dbfield": "nntag_name", "type": "text", "filtertype": "textbox"},
];

const nntagpickerfilter = [
	{"field":"nntagalias_id", "operation": "<>", "value": "48"},	// Page
	{"field":"nntagalias_id", "operation": "<>", "value": "1"},		// Brand
	{"field":"nntag_id", "operation": "<>", "value": "99"},			// Promo
	{"field":"nntag_official", "operation": "=", "value": "1"},
	{"field":"nntag_url", "operation": "<>", "value": ""}
];

const basicFields = [
	{"label":"Primary Image", "field": "nnproductrequest_image", "value": "", "input": "image", "mode": "readonly"},
	{"label":"Seller SKU", "field": "nnproductrequest_orgsku", "value": "", "input": "textbox", "mode": "normal"},
	{"label":"Full Name / Display", "field": "nnproductrequest_fullname", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Parent Product", "field": "nnproductalias_fullname", "value": "", "input": "picker", "mode": "required",  "pickerfields": nnproductaliaspickerfields, "pickerfilters": nnproductaliaspickerfilter},

	{"label":"Promo Option Display", "field": "nnproductrequest_subname", "value": "", "input": "textbox", "mode": "normal", "info":"Displayed under product promo options in website"},
	{"label":"Promo Item", "field": "nnproductrequest_promoitem", "value": "1", "input": "hidden", "mode": "normal"},

	{"label":"Last Updated", "field": "nnproductrequest_lastupdate", "value": "", "input": "updatetime", "mode": "readonly"},
	{"label":"Price", "field": "nnproductrequest_price", "value": "", "input": "currency", "mode": "required"},


	{"label":"Promo Image", "field": "nnproductrequest_promoimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":500, "imageheight":400, "imageformat":"jpg", "uploadtype":"https", "filehost": "cdn.ngnw.ph", "filelocation": "images/product/promotion", "info":"For website promo display area"},


	{"label":"Write-up", "field": "nnproductrequest_description", "value": "", "input": "textarea", "mode": "normal"},
	{"label":"Teaser/Summary", "field": "nnproductrequest_summary", "value": "", "input": "textarea", "mode": "normal"},

	{"label":"Status", "field": "docstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
		{"value":1,"display":"Draft"},
		{"value":2,"display":"For Approval"},
		{"value":3,"display":"Approved"},
		{"value":4,"display":"Declined"},
		{"value":5,"display":"For Adjustments"}
	]},

	{"label":"Latest Feedback", "field": "nnproductrequest_feedback", "value": "", "input": "textarea", "mode": "readonly"},

	{"label":"Seller Notes/Response", "field": "nnproductrequest_usernotes", "value": "", "input": "textarea", "mode": "normal"},

];


const productDimensionfields = [
	{"label":"Weight (Kilograms)", "field": "nnproductrequest_pkgweight", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Length (cm)", "field": "nnproductrequest_pkglength", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Width (cm)", "field": "nnproductrequest_pkgwidth", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Height (cm)", "field": "nnproductrequest_pkgheight", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Volume (Liters)", "field": "nnproductrequest_pkgvolume", "value": "", "input": "numeric", "mode": "required"},
	{"label":"Irregular/Not Boxed", "field": "nnproductrequest_pkgirregular", "value": "", "input": "checkbox", "mode": "required"},
	{"label":"Storage Instructions", "field": "nnproductstorage_id", "value": "", "input": "combo", "mode": "required", "options": [
		{"value":1,"display":"N/A"},
		{"value":2,"display":"Cool Dry Place"},
		{"value":3,"display":"Refrigerated"},
		{"value":4,"display":"Frozen"},
	]},
	{"label":"Shelf Life", "field": "nnproductrequest_shelflife", "value": "", "input": "textbox", "mode": "normal"},
];



const subformFields = [
	{
		"subformid": "nnproductimage",
		"label": "Images",
		"table": "nnproductimage",
		"sort": "nnproductimage_primary desc",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Image",
				"field": "nnproductimage_url",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg", // OPTIONAL: [png, jpg] default png
				"mode": "required",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "images/product/main/full", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "fit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 2048,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 2048,	// OPTIONAL: Will be required for some imagemode
			},
			{
				"label": "Description",
				"field": "nnproductimage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
			{
				"label": "Main Imaage",
				"field": "nnproductimage_primary",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal",
				"info": "Only one main image"
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "nnproducttag",
		"label": "Categories",
		"table": "nnproducttag",
		"sort": "nnproducttag_id ",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Category",
				"field": "nntag_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
				"pickerfields": nntagpickerfields,
				"pickerfilters": nntagpickerfilter
			},
			{
				"label": "Primary",
				"field": "nnproducttag_primary",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	}
];

const ProductrequestpromoPage = ({location}) => {

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		//var customparam = {};
		var customparam = JSON.parse(JSON.stringify(params));

		customparam.nnproductrequest_promoitem = 1;
		//callback({"status":"Error"}); return;

		handleProductAlias(params, token, function(hasalias, aliasdata) {
			if (hasalias) {
				if (aliasdata.nnbrand_id>0) {
					customparam.nnbrand_id = aliasdata.nnbrand_id;
					customparam.nnproductrequest_name = aliasdata.nnproduct_name;
					customparam.nnproductalias_id = aliasdata.nnproductalias_id;
					customparam.onsorganization_id = aliasdata.onsorganization_id;
				}
			}
			//console.log("Submit",customparam); console.log("aliasdata",aliasdata); callback({"status":"Error", "message":"Debug"}); return;

			webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, callback);
		});
	}

	function handleProductAlias(params, token, callback)
	{
		const copyfieldlist = [
			"onsorganization_id", "nnbrand_id", "nnproduct_name"
		];
		var found = false;
		var output = {
			nnproductalias_id: 0,
			onsorganization_id: 0,
			nnbrand_id: 0,
			nnproduct_name: ""
		}

		if (params.hasOwnProperty("nnproductalias_id")) {
			if (params.nnproductalias_id !== null) {
				if (params.nnproductalias_id>0) {
					output.nnproductalias_id = params.nnproductalias_id;
					found = true;
					webappsAPI.loadRecord("nnproduct", params.nnproductalias_id, token).then(dataresponse => {
						if (dataresponse.hasOwnProperty("fields")) {
							var rowidx = 0;
							var colidx = 0;
							var tmpfield = "";
							while (rowidx < dataresponse.fields.length) {
								colidx = 0;
								while (colidx < dataresponse.fields[rowidx].length) {
									tmpfield = dataresponse.fields[rowidx][colidx].field;
									if (copyfieldlist.includes(tmpfield)) {
										output[tmpfield] = dataresponse.fields[rowidx][colidx].value;
									}
									colidx++;
								} // while col
								rowidx++;
							} // while row
							callback(found, output);
							return;
						} // if fields
						callback(found, output);
					});
					return;
				} // has alias
			} // alias not null
		} // has alias property
		callback(found, output);
	} // handleProductAlias

	return <ProductRequestEditorPage
				location={location}
				allowadd={true}
				customSearchFilter={[
					{"field":"docstatus_id", "operation":"<>", "value": 3}, // not yet approved
					{"field":"nnproductrequest_promoitem", "operation":"<>", "value": 0}
				]}
				formFields={[basicFields.concat(productDimensionfields)]}
				editSubFormFields={subformFields}
				customSubmit={customSubmit}
			/>
}

export default ProductrequestpromoPage;
